const state = {
  time: null,
  shoppingId: null,
  userId: null,
  rents: [],
  eventSource: null // Adiciona o estado para o eventSource
}

const mutations = {
  setRents(state, rentsData) {
    state.rents = rentsData
  },
  setTime(state, time) {
    state.time = time
  },
  setShoppingId(state, shoppingId) {
    state.shoppingId = shoppingId
  },
  setUserId(state, userId) {
    state.userId = userId
  },
  setEventSource(state, eventSource) {
    state.eventSource = eventSource
  },
  closeEventSource(state) {
    if (state.eventSource) {
      state.eventSource.close() // Fecha a conexão SSE
      state.eventSource = null // Remove a referência após o fechamento
    }
  }
}

const actions = {
  startSSE({ commit, state }) {
    const shoppingId = state.shoppingId
    const userId = state.userId

    if (!shoppingId || !userId) {
      console.error('shoppingId ou userId não estão definidos.')
      return
    }
    const apiUrl = process.env.VUE_APP_API_URL
    const url = `${apiUrl}/sse/time?shoppingId=${encodeURIComponent(
      shoppingId
    )}&userId=${encodeURIComponent(userId)}`

    const eventSource = new EventSource(url)

    // Listener para o evento 'time'
    eventSource.addEventListener('time', (event) => {
      const utcDate = new Date(event.data)
      commit('setTime', utcDate)
    })
    // Listener para o evento 'updateRents'
    eventSource.addEventListener('updateRents', (event) => {
      const rentsData = JSON.parse(event.data)
      commit('setRents', rentsData)
    })

    // Armazena o eventSource para fechar a conexão, se necessário
    commit('setEventSource', eventSource)
  },
  stopSSE({ commit }) {
    commit('closeEventSource') // Fecha a conexão com o SSE
  },
  setUserAndShopping({ commit, dispatch }, { userId, shoppingId }) {
    commit('setUserId', userId)
    commit('setShoppingId', shoppingId)
    dispatch('startSSE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
