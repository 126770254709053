import api from '@/services/api'

const RESOURCE_URI = '/rent'
const storeData = localStorage.getItem('happyjungle')
  ? JSON.parse(localStorage.getItem('happyjungle'))
  : null
const storeID =
  storeData && storeData.store && storeData.store.currentStore
    ? storeData.store.currentStore.id
    : null

const fetchRents = () => api.get(RESOURCE_URI)
const fetchRentById = (id) => api.get(`${RESOURCE_URI}/${id}`)
const createRent = (rent) =>
  api.post(RESOURCE_URI, { ...rent, shoppingID: storeID })
const updateRent = ({
  id,
  toyId,
  periodId,
  customerId,
  userId,
  storeId,
  notes,
  status
}) =>
  api.put(`${RESOURCE_URI}/${id}`, {
    id,
    toyId,
    periodId,
    customerId,
    userId,
    storeId,
    notes,
    status,
    shoppingID: storeID
  })
const removeRent = (id) => api.delete(`${RESOURCE_URI}/${id}`)

const startRent = ({ toyId, periodId, storeId, customer }) =>
  api.post(`${RESOURCE_URI}/start`, {
    toyId,
    periodId,
    storeId,
    customer,
    shoppingID: storeID
  })

const pauseRent = ({ id }) =>
  api.post(`${RESOURCE_URI}/${id}/pause`, { shoppingID: storeID })

const resumeRent = ({ id }) =>
  api.post(`${RESOURCE_URI}/${id}/resume`, { shoppingID: storeID })

const closeRent = async ({ id, checkoutId, payments }) => {
  const pauseResponse = await pauseRent({ id })
  if (pauseResponse.status == 'paused') {
    const closeResponse = await api.post(`${RESOURCE_URI}/${id}/close`, {
      checkoutId,
      payments,
      shoppingID: storeID
    })
    return closeResponse
  } else {
    throw new Error('Erro ao pausar o aluguel.')
  }
}

const transferRent = ({ id, toyId }) =>
  api.post(`${RESOURCE_URI}/${id}/transfer`, { toyId, shoppingID: storeID })

const cancelRent = async ({ id, reason }) => {
  const pauseResponse = await pauseRent({ id })
  if (pauseResponse.status == 'paused') {
    const cancelResponse = await api.post(`${RESOURCE_URI}/${id}/cancel`, {
      reason,
      shoppingID: storeID
    })
    return cancelResponse
  } else {
    throw new Error('Erro ao pausar o aluguel.')
  }
}

const addExtraTime = ({ id, time, reason }) =>
  api.post(`${RESOURCE_URI}/${id}/extra-time`, {
    time,
    reason,
    shoppingID: storeID
  })

const reopenLastRent = ({ id }) =>
  api.post(`${RESOURCE_URI}/${id}/reopen`, { shoppingID: storeID })

const changePeriod = ({ id, periodId }) =>
  api.post(`${RESOURCE_URI}/${id}/change-period`, {
    periodId,
    shoppingID: storeID
  })

const addProduct = ({ id, productId, quantity }) =>
  api.post(`${RESOURCE_URI}/${id}/product`, {
    productId,
    quantity,
    shoppingID: storeID
  })

const removeProduct = ({ id, productId }) =>
  api.delete(`${RESOURCE_URI}/${id}/product/${productId}`, {
    shoppingID: storeID
  })

export default {
  fetchRents,
  fetchRentById,
  createRent,
  updateRent,
  removeRent,
  startRent,
  pauseRent,
  resumeRent,
  closeRent,
  transferRent,
  cancelRent,
  addExtraTime,
  reopenLastRent,
  changePeriod,
  addProduct,
  removeProduct
}
